<template>
  <p class="-mt-2 mb-4 text-center text-base antialiased md:m-0 md:text-left">
    <template v-if="time"
      >{{ t('vet.next_available_time') }} {{ ' ' }}
    </template>
    <span v-if="time">{{ time }}</span>
  </p>
</template>

<script setup lang="ts">
const { locale, t } = useI18n();

const props = defineProps({
  timeSlotDelay: {
    type: Number,
    required: true,
  },
});

interface ApiResponse {
  success: boolean;
  data?: {
    adjustedTime: string;
  };
  errorMessage?: string;
}

const time = ref('');

const fetchTime = async () => {
  const { data } = await useFetch<ApiResponse>(
    `/ecom-api/editorial/get-next-available-time`,
    {
      method: 'POST',
      body: {
        regionCode: useRegionCode(),
        timeSlotDelay: props.timeSlotDelay,
        locale: locale.value,
      },
    }
  );

  // Watch for changes in the fetched data
  watchEffect(() => {
    if (data.value && data.value.success) {
      time.value = data.value.data?.adjustedTime || '';
    }
  });
};

fetchTime();
</script>
